import fetchAndStoreAttachments from '@database/fetchAndStoreAttachments';

const getAfter = async ({ added = [], updated = [] }, isSyncDocuments) => {
  for (let i = 0; i < added.length; i++) {
    const databaseDoc = added[i];
    const documents = databaseDoc?.entity?.documents;
    await databaseDoc.atomicUpdate((oldData) => {
      oldData.isWorkbench = true;
      return oldData;
    });

    // Add attachments when document synchronization is enabled
    if (isSyncDocuments) {
      await fetchAndStoreAttachments(documents, databaseDoc);
    }
  }

  for (let i = 0; i < updated.length; i++) {
    const databaseDoc = updated[i];
    const documents = databaseDoc?.entity?.documents;
    await databaseDoc.atomicUpdate((oldData) => {
      oldData.isWorkbench = true;
      return oldData;
    });

    // Update attachments when document synchronization is enabled
    await fetchAndStoreAttachments(documents, databaseDoc, isSyncDocuments);
  }
};

export default getAfter;
