import { compareByReference } from '@geomagic/geonam';

import { getTypedAttributeValue } from '../../../utils';

const getDefaultValuesFromEntity = (attributeTypes, entity) =>
  attributeTypes.reduce((prev, attributeType, index) => {
    const attributeValue = entity?.attributeValues?.find((aV) => compareByReference(attributeType, aV.attributeType));
    const typedValue = getTypedAttributeValue(attributeType, attributeValue?.value);

    if (typedValue !== null && typedValue !== undefined) {
      prev[index] = typedValue;
    }

    return prev;
  }, {});

export default getDefaultValuesFromEntity;
