import { getReference } from '@geomagic/geonam';

import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getDefaultSync from '@database/getDefaultSync';
import defaultSelector from '@database/selector';
import getDiffs from '@database/getDiffs';
import MutationConnectDispatchFormElementBlock from '@graphql/mutations/MutationConnectDispatchFormElementBlock';
import QueryDispatch from '@graphql/queries/QueryDispatch';

import handleFetchError from '../../database/handleFetchError';
import handleSyncError from '../../database/handleSyncError';

const DISPATCH_KEY = 'relevantDispatches';
const UPDATE_KEY = 'id';
const DISPATCH_ASSIGNMENT_SOURCE_RELATION_TYPE_ID = 'Dispatch_assignment_source';

/**
 * Updates relevant dispatches.
 * @param {object} updateProps
 * @param {object} updateProps.client
 * @param {object} updateProps.database
 * @param {object} updateProps.entity
 * @param {Array.<object>} updateProps.entityClasses
 * @param {object} updateProps.mapProps
 * @param {object} updateProps.patchedEntity
 */
const updateRelevantDispatches = async (updateProps) => {
  const { client, database, entity, entityClasses, idMap, mapProps, patchedEntity, syncErrors } = updateProps;
  const relevantDispatches = entity[DISPATCH_KEY];
  const patchedRelevantDispatches = patchedEntity[DISPATCH_KEY];

  if (patchedRelevantDispatches?.length > 0) {
    const { added } = getDiffs(relevantDispatches, patchedRelevantDispatches, UPDATE_KEY);

    if (added.length > 0) {
      const assignmentReference = getReference(entity);
      const relations = [
        {
          relationTypeId: DISPATCH_ASSIGNMENT_SOURCE_RELATION_TYPE_ID,
          entityReferences: [assignmentReference],
        },
      ];

      for (let i = 0; i < added.length; i++) {
        let createdEntityReference;
        try {
          const relevantDispatch = added[i];
          const selector = { [ENTITY_SELECTOR_KEY]: relevantDispatch.id, ...defaultSelector };
          const dispatchDoc = await database.dispatches.findOne({ selector }).exec();

          if (dispatchDoc) {
            createdEntityReference = await getDefaultSync({
              client,
              doc: dispatchDoc,
              entityClasses,
              mapProps,
              refetchQuery: QueryDispatch,
              relations,
              syncErrors,
            });

            if (createdEntityReference && relevantDispatch?.blockId) {
              const { blockId } = relevantDispatch;
              const formElementBlockId = idMap.has(blockId) ? idMap.get(blockId) : blockId;
              await client.mutate({
                mutation: MutationConnectDispatchFormElementBlock,
                variables: {
                  dispatchReference: createdEntityReference,
                  formElementBlockId,
                },
              });
            }
          }
        } catch (error) {
          handleFetchError({ error, isDefaultThrow: false });
          handleSyncError({ entityReference: createdEntityReference, error, syncErrors });
        }
      }
    }
  }
};

export default updateRelevantDispatches;
