/**
 * The Dialog component returns a customized dialog window.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ContentRoot } from '@geomagic/layout';

const CustomDialog = (props) => {
  const {
    actions,
    classes: dialogClasses,
    content,
    dialogContentProps,
    handleClose,
    maxWidth = 'sm',
    open = false,
    scrollableContent = true,
    title,
    ...dialogProps
  } = props;

  return (
    <Dialog
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}
      maxWidth={maxWidth}
      onClose={handleClose}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
      open={open}
      {...dialogProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content &&
        (scrollableContent ? (
          <ContentRoot withPadding={false} withCustomScrollbar>
            <DialogContent dividers {...dialogContentProps}>
              {content}
            </DialogContent>
          </ContentRoot>
        ) : (
          <DialogContent dividers {...dialogContentProps}>
            {content}
          </DialogContent>
        ))}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  classes: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  dialogContentProps: PropTypes.object,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  open: PropTypes.bool.isRequired,
  scrollableContent: PropTypes.bool,
  title: PropTypes.string,
};

export default CustomDialog;
