/**
 * A hook to save a state value to the local storage.
 *
 */

import { useEffect, useState } from 'react';

export const PREFERENCES_PREFIX = 'Preferences.';

export const getStickyValue = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key) ?? 'null') ?? undefined;
  } catch {
    return undefined;
  }
};

const setStickyValue = (key, value) => {
  try {
    return window.localStorage.setItem(key, JSON.stringify(value));
  } catch {
    /* NOOP */
  }
};

const useStickyState = (key, defaultValue) => {
  const prefixedKey = PREFERENCES_PREFIX + key;
  const [value, setValue] = useState(() => {
    const stickyValue = getStickyValue(prefixedKey);

    return stickyValue !== undefined ? stickyValue : defaultValue;
  });

  /**
   * EFFECTS
   */

  useEffect(() => {
    setStickyValue(prefixedKey, value);
  }, [prefixedKey, value]);

  return [value, setValue];
};

export default useStickyState;
