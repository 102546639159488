import getDownloadUrl from './getDownloadUrl';
import getFileByDownloadUrl from './getFileByDownloadUrl';
import getFilePropsFromDocumentEntity from './getFilePropsFromDocumentEntity';

const fetchAndStoreAttachments = async (documents = [], databaseDoc, isSyncDocuments = true) => {
  for (let i = 0; i < documents.length; i++) {
    const doc = documents[i];
    const { downloadPath, hash, name, type } = getFilePropsFromDocumentEntity(doc);
    const url = getDownloadUrl(downloadPath);
    const file = await getFileByDownloadUrl({ url, name, options: { type } });
    const isExistingFile = file && hash;
    const attachment = databaseDoc.getAttachment(hash);

    if (isExistingFile && (isSyncDocuments || attachment)) {
      await databaseDoc.putAttachment({ id: hash, data: file, type });
    }
  }
};

export default fetchAndStoreAttachments;
