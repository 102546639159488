import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';

import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import useOfflineDocuments from '../../utils/useOfflineDocuments';

const useStyles = makeStyles()(({ spacing }) => ({
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
}));

const DownloadOfflineButton = (props) => {
  const { doc, item, isLoadingAttachments, isOnline, setIsLoadingAttachments } = props;

  const { id, hash } = item;
  const fileHash = hash || id;

  const { classes } = useStyles();
  const { addOfflineDocument, getDocument } = useOfflineDocuments(doc);
  const isDisabled = !isOnline || isLoadingAttachments;

  const hasOfflineDocument = useMemo(() => getDocument(fileHash), [fileHash, getDocument]);

  /* EVENT HANDLER */

  const handleOnSwitch = async (_event) => {
    setIsLoadingAttachments(true);

    await addOfflineDocument(item).finally(() => {
      setIsLoadingAttachments(false);
    });
  };

  /* EFFECTS */

  return (
    <>
      {!!hasOfflineDocument ? (
        <Trigger
          {...DEFAULT_TRIGGER_ICON_PROPS}
          className={classes.trigger}
          icon={<OfflinePinIcon color="success" />}
          variant="icon"
          disabled
        />
      ) : (
        <Trigger
          {...DEFAULT_TRIGGER_ICON_PROPS}
          className={classes.trigger}
          icon={<DownloadForOfflineIcon color={isDisabled ? 'disabled' : 'warning'} />}
          onClick={handleOnSwitch}
          variant="icon"
          disabled={isDisabled}
          tooltip={i18n.t('label.addToCache')}
        />
      )}
    </>
  );
};

DownloadOfflineButton.propTypes = {
  doc: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  isLoadingAttachments: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  setIsLoadingAttachments: PropTypes.func.isRequired,
};

export default DownloadOfflineButton;
