import React from 'react';
import Typography from '@mui/material/Typography';
import { i18n } from '@geomagic/i18n';

/**
 * Show a badge with a Stage hint.
 * Either consume the predefined stage like DEMO, DEV, TEST
 * or show a configured environment name.
 */

const STAGES = ['demo', 'dev', 'test'];

const BadgeStages = () => {
  const stage = window.STAGE || process.env.REACT_APP_STAGE || '';
  const predefinedStage = STAGES.find((predefined) => stage.toLowerCase().includes(predefined));
  const isProd = stage.toLowerCase().includes('prod');

  return stage.length && !isProd ? (
    <Typography
      variant="caption"
      sx={{
        border: (theme) => `2px solid ${theme.palette.secondary.main}`,
        borderRadius: (theme) => theme.spacing(0.5),
        color: (theme) => theme.palette.secondary.main,
        fontWeight: 'bold',
        padding: (theme) => theme.spacing(0.25),
      }}
    >
      {predefinedStage ? i18n.t(`stage.${predefinedStage}`) : stage}
    </Typography>
  ) : null;
};

export default BadgeStages;
