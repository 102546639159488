const getImagesFromAttachments = async (documents, databaseDoc) => {
  const images = [];

  for (let i = 0; i < documents.length; i++) {
    const doc = documents[i];
    const attachment = databaseDoc.getAttachment(doc?.hash);

    const data = attachment && (await attachment.getData());

    if (attachment) {
      images.push({
        id: doc?.hash,
        name: doc?.name,
        url: data && URL.createObjectURL(data),
      });
    }
  }

  return images;
};

export default getImagesFromAttachments;
