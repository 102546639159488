import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Switch from '@mui/material/Switch';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { i18n } from '@geomagic/i18n';
import useStickyState from '@geomagic/nam-react-core/utils/useStickyState';

import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useOfflineTiles from './hooks/useOfflineTiles';
import getCacheSize from './utils/getCacheSize';
import { BASE_KEY } from './consts';
import bytesToSize from '../../../utils/bytesToSize';

const KEY_CHECKED_PREFIX = 'OfflineMap.checked.';
const KEY_CACHE_SIZE_PREFIX = 'OfflineMap.cacheSize.';

const OfflineSectionListItem = (props) => {
  const { adminUnit, isDisabled, isFetching, isOnline, mapProps, setIsFetching } = props;
  const { vectorTileLayers = [] } = mapProps;
  const { id: adminUnitId, displayName } = adminUnit;
  const offlineMapId = `${BASE_KEY}_${adminUnitId}`;

  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const [checked, setChecked] = useStickyState(KEY_CHECKED_PREFIX + adminUnitId, false);
  const [cacheSize, setCacheSize] = useStickyState(KEY_CACHE_SIZE_PREFIX + adminUnitId);

  const { addOfflineTiles, deleteOfflineTiles, hasOfflineTiles } = useOfflineTiles({
    adminUnit,
    mapProps,
  });

  const formatCacheString = useCallback(() => (!!cacheSize ? bytesToSize(cacheSize) : ''), [cacheSize]);

  const handleOnSwitch = async (_event, newChecked) => {
    const abortController = new AbortController();
    const execute = newChecked ? () => addOfflineTiles(abortController) : deleteOfflineTiles;

    const handleAbort = () => {
      abortController.abort('cancel');
      deleteOfflineTiles();
      setIsFetching(false);
    };

    setIsFetching(true);

    await enqueueLoadingSnackbar({
      loadingText: newChecked
        ? i18n.t('offlineMap.notification.loadingCreate')
        : i18n.t('offlineMap.notification.loadingDelete'),
      finishedText: newChecked ? i18n.t('offlineMap.notification.created') : i18n.t('offlineMap.notification.deleted'),
      cancelText: i18n.t('offlineMap.notification.cancel'),
      func: execute,
      cancelFunc: newChecked ? handleAbort : null,
      preventDuplicate: true,
      finishedVariant: 'success',
    }).then(async () => {
      const offlineLayerIds = vectorTileLayers.reduce((prev, curr) => {
        const { id } = curr;
        const cacheId = `${offlineMapId}_${id}`;
        prev.push(cacheId);
        return prev;
      }, []);
      const newCacheSize = await getCacheSize([offlineMapId, ...offlineLayerIds]);
      setChecked(newChecked);
      setCacheSize(newCacheSize);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    const checkCache = async () => {
      const isInCache = await hasOfflineTiles();

      if (!isInCache) {
        setCacheSize(null);
        setChecked(isInCache);
      }
    };

    if (!isDisabled) {
      checkCache();
    }
  }, [hasOfflineTiles, isDisabled, setCacheSize, setChecked]);

  return (
    <ListItem disableGutters divider>
      <ListItemText primary={displayName} secondary={checked ? formatCacheString() : ''} />
      <ListItemSecondaryAction>
        <Switch
          checked={checked}
          color="primary"
          disabled={isDisabled || isFetching || (!isOnline && !checked)}
          onChange={handleOnSwitch}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

OfflineSectionListItem.propTypes = {
  adminUnit: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

export default OfflineSectionListItem;
