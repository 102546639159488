import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRxDB } from 'rxdb-hooks';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@geomagic/core';
import { ContentRoot } from '@geomagic/layout';
import { i18n } from '@geomagic/i18n';

import OfflineMapSections from '../OfflineMap/OfflineMapSections';
import OfflineDocuments from '../OfflineDocuments';
import bytesToSize from '../../../utils/bytesToSize';

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  };
});

const OfflineCache = (props) => {
  const { adminUnits, className, isMobile, isOnline, mapProps } = props;
  const { vectorTileServerUrl } = mapProps;
  const database = useRxDB();
  const { classes } = useStyles(props);

  const [cacheSize, setCacheSize] = useState(0);

  const assignments = database.collections.assignments;
  const dispatches = database.collections.dispatches;

  const handleSetCacheSize = useCallback(async () => {
    const filteredAttachment = [];
    const assignmentDocs = await assignments.find().exec();
    const dispatchDocs = await dispatches.find().exec();

    assignmentDocs?.forEach((curr) => {
      const allAttachments = curr.allAttachments();
      if (!isEmpty(allAttachments)) {
        filteredAttachment.push(...allAttachments);
      }
    });

    dispatchDocs?.forEach((curr) => {
      const allAttachments = curr.allAttachments();
      if (!isEmpty(allAttachments)) {
        filteredAttachment.push(...allAttachments);
      }
    });

    const size = filteredAttachment.reduce((prev, next) => prev + next.length, 0);

    setCacheSize(size);
  }, [assignments, dispatches]);

  /* EFFECTS */

  useEffect(() => {
    handleSetCacheSize();
  }, [handleSetCacheSize]);

  return (
    <ContentRoot
      className={classNames(classes.root, className)}
      scrollable
      withPadding={false}
      withCustomScrollbar={!isMobile}
    >
      <ListSubheader>
        {i18n.t('label.preference.offlineDocuments.title', {
          variables: {
            size: bytesToSize(cacheSize),
          },
        })}
      </ListSubheader>
      <Divider />
      <Box sx={{ padding: 2 }}>
        <OfflineDocuments dispatches={dispatches} handleSetCacheSize={handleSetCacheSize} isOnline={isOnline} />
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {i18n.t('label.preference.offlineDocuments.subtitle')}
        </Typography>
      </Box>
      <Divider />
      <ListSubheader>{i18n.t('label.preference.offlineMap.title')}</ListSubheader>
      <Divider />
      <Box sx={{ padding: 2 }}>
        {vectorTileServerUrl ? (
          <>
            <Typography variant="body2">{i18n.t('label.preference.offlineMap.subtitle')}</Typography>
            <OfflineMapSections adminUnits={adminUnits} isOnline={isOnline} mapProps={mapProps} />
          </>
        ) : (
          <Typography variant="body2">{i18n.t('label.preference.offlineMap.notAvailable')}</Typography>
        )}
      </Box>
    </ContentRoot>
  );
};

OfflineCache.propTypes = {
  adminUnits: PropTypes.array.isRequired,
  className: PropTypes.string,
  isOnline: PropTypes.bool,
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
};

export default OfflineCache;
