import gql from 'graphql-tag';

import FragmentAttributeValues from '../fragments/FragmentAttributeValues';

export const QueryDocument = gql`
  ${FragmentAttributeValues}
  query QueryDocument($entityReference: EntityReferenceInput!) {
    entity: findEntity(entityReference: $entityReference) {
      ...AttributeValues
      id
      className
      displayName
      entityType {
        id
        className
      }
      properties
    }
  }
`;

export default QueryDocument;
