import getDefaultModifiedOn from '@database/getDefaultModifiedOn';

import getAssignmentAfter from './assignment/getAfter';
import getAssignmentBeforeInsertDoc from './assignment/getBeforeInsertDoc';
import getAssignmentQueryConfig from './assignment/getQueryConfig';
import transformAssignment from './assignment/transform';

import getDispatchAfter from './dispatch/getAfter';
import getDispatchQueryConfig from './dispatch/getQueryConfig';

const getFetchConfig = (mapProps, isSyncDocuments) => {
  const { srid, world } = mapProps;
  const mapVariables = { srid, worldId: world?.id };

  return [
    {
      collectionName: 'assignments',
      query: getAssignmentQueryConfig(mapVariables),
      getModifiedOn: getDefaultModifiedOn,
      beforeInsertDoc: getAssignmentBeforeInsertDoc,
      after: getAssignmentAfter,
      transform: transformAssignment,
    },
    {
      collectionName: 'dispatches',
      query: getDispatchQueryConfig(mapVariables),
      getModifiedOn: getDefaultModifiedOn,
      after: (syncElements) => getDispatchAfter(syncElements, isSyncDocuments),
    },
  ];
};

export default getFetchConfig;
