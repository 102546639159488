/**
 * A info box for a dispatch displayed in the map.
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useRxDocument } from 'rxdb-hooks';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { makeStyles, Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { getFormattedAttributeValue } from '@geomagic/nam-react-core/utils';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getPatch from '@database/getPatch';

import AddAPhotoAction from './AddAPhotoAction';
import DCMAttributes from './DCMAttributes';

const FILTERED_READ_ONLY_CODES = ['property.name', 'property.referenceNumber', 'property.processingStatus'];

const N_DASH = '–';
const PAPER_SIZE = 300;
const PAPER_XS_SIZE = 200;
const PAPER_SM_SIZE = 250;

const useStyles = makeStyles()(({ breakpoints, palette, shape, spacing }) => ({
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    gap: spacing(),
  },
  content: {},
  listItem: {
    margin: 0,
    padding: spacing(0.5, 1),
  },
  listItemText: {
    margin: 0,
  },
  paper: {
    borderRadius: shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: PAPER_SIZE,
    padding: 0,
    pointerEvents: 'auto',
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    width: PAPER_SIZE,
    zIndex: 2,
    [breakpoints.down('sm')]: {
      maxHeight: PAPER_SM_SIZE,
      width: PAPER_SM_SIZE,
    },
    [breakpoints.down('xs')]: {
      maxHeight: PAPER_XS_SIZE,
      width: PAPER_XS_SIZE,
    },
  },
  title: {
    marginRight: spacing(0.5),
  },
  titleContainer: {
    borderBottom: `1px solid ${palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1),
  },
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
  triggerEdit: {
    height: MOBILE_TRIGGER_SIZE,
    minWidth: MOBILE_TRIGGER_SIZE + 16,
    width: MOBILE_TRIGGER_SIZE + 16,
  },
}));

const getInfoValue = (attributeType, attributeValue) => {
  return { label: attributeType.name, value: getFormattedAttributeValue(attributeType, attributeValue) };
};

const isReadOnlyAttributeType = (attributeType) =>
  attributeType.readOnly && !FILTERED_READ_ONLY_CODES.some((filteredCode) => attributeType.code === filteredCode);
const isDCMAttributeType = (attributeType, dispatchAttribTypes) =>
  dispatchAttribTypes.some((dispatchAttribType) => dispatchAttribType.id === attributeType.id);
const isNotNullish = (value) => value !== undefined && value !== null;

const getInfoValues = (entity, allAttributeTypes, dispatchAttribTypes) => {
  const { attributeValues } = entity;
  const filteredAttributeTypes = allAttributeTypes.filter(
    (attributeType) => isReadOnlyAttributeType(attributeType) || isDCMAttributeType(attributeType, dispatchAttribTypes)
  );
  return filteredAttributeTypes.map((attributeType) => {
    const foundAttributeValue = attributeValues?.find(
      (attributeValue) => attributeValue.attributeType.id === attributeType.id
    );
    return getInfoValue(attributeType, foundAttributeValue?.value);
  });
};

const DispatchInfoBox = (props) => {
  const {
    allAttributeTypes,
    dispatchAttribTypes,
    documentTypeId,
    isDispatchCreation,
    isSmallMobile,
    entityClasses,
    selectedEntityId,
    onClose,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const { classes } = useStyles(props);

  const { result: dispatch, isFetching } = useRxDocument('dispatches', selectedEntityId, {
    idAttribute: ENTITY_SELECTOR_KEY,
  });

  const entity = dispatch?.getPatchedEntity();
  const showInfoBox = !!selectedEntityId && !!dispatch && !isFetching && !isDispatchCreation;

  const { className, displayName } = entity || {};
  const entityType = entity?.entityType?.id ? getEntityType(entityClasses, className, entity?.entityType?.id) : {};

  const title = displayName || entityType?.name;

  const isDraft = !!dispatch?.draft;

  const infoValues = useMemo(() => {
    if (entity) {
      return getInfoValues(entity, allAttributeTypes, dispatchAttribTypes);
    }
    return [];
  }, [allAttributeTypes, dispatchAttribTypes, entity]);

  /* EVENT HANDLER */

  const handleOnBack = async () => {
    setIsEdit(false);
  };

  const handleUpdateDraft = async (newPatch) => {
    const jsonPatch = dispatch?.jsonPatch;

    await dispatch.atomicUpdate((oldData) => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  const handleFillAttributes = async (values) => {
    const newPatch = {
      op: 'replace',
      path: `/attributeValues`,
      value: values,
    };

    await handleUpdateDraft(newPatch);
    setIsEdit(false);
  };

  /**
   *  COMPONENTS
   */

  const CloseActionComponent = (
    <Trigger
      {...DEFAULT_TRIGGER_ICON_PROPS}
      className={classes.trigger}
      onClick={onClose}
      icon={<CloseIcon />}
      tooltip={i18n.t('tooltip.close')}
    />
  );

  return (
    <Zoom in={showInfoBox} exit={false}>
      <Paper elevation={4} className={classes.paper}>
        <div className={classes.titleContainer}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography className={classes.title} color="primary" noWrap variant="h6">
              {title}
            </Typography>
            {CloseActionComponent}
          </Box>
          <div className={classes.actions}>
            <AddAPhotoAction doc={dispatch} documentTypeId={documentTypeId} />
            {!!isDraft && (
              <Trigger
                {...DEFAULT_TRIGGER_ICON_PROPS}
                className={classes.triggerEdit}
                color="primary"
                onClick={() => setIsEdit(true)}
                icon={<EditIcon />}
                tooltip={i18n.t('tooltip.edit')}
                variant="contained"
              />
            )}
          </div>
        </div>
        <ContentRoot className={classes.content} withCustomScrollbar withPadding={false}>
          {infoValues.map((infoValue, index) => {
            const { label, value } = infoValue;

            return (
              <ListItem key={'infoValue-' + index} className={classes.listItem} disableGutters>
                <ListItemText
                  className={classes.listItemText}
                  primary={label}
                  primaryTypographyProps={{ color: 'textSecondary', noWrap: true, variant: 'body2' }}
                  secondary={isNotNullish(value) ? value : N_DASH}
                  secondaryTypographyProps={{ color: 'textPrimary', noWrap: true, variant: 'body1' }}
                />
              </ListItem>
            );
          })}
        </ContentRoot>
        {isDraft && showInfoBox && (
          <DCMAttributes
            allAttributeTypes={allAttributeTypes}
            doc={dispatch}
            dispatchAttribTypes={dispatchAttribTypes}
            isAttributesFill={isEdit}
            isSmallMobile={isSmallMobile}
            onBack={handleOnBack}
            onConfirm={handleFillAttributes}
          />
        )}
      </Paper>
    </Zoom>
  );
};

DispatchInfoBox.propTypes = {
  allAttributeTypes: PropTypes.array,
  dispatchAttribTypes: PropTypes.array,
  documentTypeId: PropTypes.string.isRequired,
  entityClasses: PropTypes.array.isRequired,
  isDispatchCreation: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  selectedEntityId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default DispatchInfoBox;
