import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { AutoForm } from '@geomagic/forms';
import { PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import MutationLogin from '@graphql/mutations/MutationLogin';
import getSchemaAndUI from './getSchemaAndUI';
import AboutDialog from '../AppBar/AboutDialog';
import BadgeStages from '../AppBar/BadgeStages';

const AUTO_FORM_ID = 'LOGIN_FORM';
const SLIDE_ANIMATION_MS = 200;
const LOGIN_BOX_MAX_WIDTH = 500;

const Login = (props) => {
  const { onLogin, ssoLink } = props;
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const [schemaAndUI] = useState(() => getSchemaAndUI());

  /* MUTATIONS */

  const [login, { loading }] = useMutation(MutationLogin);

  /* EVENT HANDLER */

  const handleLogin = (variables) =>
    login({ variables }).then(({ data }) => {
      onLogin(data?.login);
    });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: 2,
      }}
    >
      <AboutDialog isOpen={isOpenAbout} onClose={() => setIsOpenAbout(false)} />
      <Slide in timeout={SLIDE_ANIMATION_MS}>
        <Paper
          sx={{
            padding: 2,
            maxWidth: LOGIN_BOX_MAX_WIDTH,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: (theme) => theme.spacing(2),
          }}
          variant="outlined"
        >
          <BadgeStages />
          <Box>
            <AutoForm id={AUTO_FORM_ID} onSubmit={handleLogin} isSubmitOnEnter={false} {...schemaAndUI}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  marginTop: 1,
                }}
              >
                <Trigger {...PRIMARY_TRIGGER_PROPS} isLoading={loading} disabled={loading} type="submit">
                  {i18n.t('button.login')}
                </Trigger>
              </Box>
            </AutoForm>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: (theme) => theme.spacing(),
                gap: (theme) => theme.spacing(2),
                cursor: 'pointer',
              }}
            >
              <Trigger {...SECONDARY_TRIGGER_PROPS} type="text" onClick={() => setIsOpenAbout(true)}>
                {i18n.t('label.contact')}
              </Trigger>
              {ssoLink}
            </Box>
          </Box>
        </Paper>
      </Slide>
    </Box>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  ssoLink: PropTypes.element,
};

export default Login;
