import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Skeleton from '@mui/material/Skeleton';

import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import FileAvatar from '@geomagic/nam-react-core/components/FileAvatar';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';
import useShowPrompt from '@utils/useShowPrompt';

import DownloadOfflineButton from './DownloadOfflineButton';
import useOfflineDocuments from '../../utils/useOfflineDocuments';
import getFilePropsFromDocumentEntity from '../../database/getFilePropsFromDocumentEntity';

const getWebDavAppName = (webDavUrl) => {
  let appName;

  if (webDavUrl) {
    switch (true) {
      case webDavUrl.startsWith('ms-excel'):
        appName = 'Excel';
        break;
      case webDavUrl.startsWith('ms-powerpoint'):
        appName = 'PowerPoint';
        break;
      case webDavUrl.startsWith('ms-word'):
        appName = 'Word';
        break;
      default:
        appName = 'Office';
    }
  }

  return appName;
};

const useStyles = makeStyles()(({ breakpoints }) => {
  return {
    root: {
      padding: 0,
      width: '100%',
    },
    listItem: {
      minHeight: 70,
    },
    trigger: {
      [breakpoints.down('md')]: {
        height: MOBILE_TRIGGER_SIZE,
        width: MOBILE_TRIGGER_SIZE,
      },
    },
    skeletonAvatar: {
      height: 40,
      width: 40,
    },
    skeletonSubtitle: {
      width: 100,
    },
    skeletonTitle: {
      width: 150,
    },
  };
});

const DocumentList = (props) => {
  const {
    activeId,
    className,
    doc,
    documents,
    isLoading,
    isOfflineCachePermanent = true,
    isOnline,
    loadingItems,
    onDelete,
  } = props;

  const { classes } = useStyles();
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const toggleActionDrawer = useToggleActionDrawer();

  const { addOfflineDocument, deleteOfflineDocument, fetchDocument, getDocument } = useOfflineDocuments(doc);
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);

  const listItemTypographyProps = {
    noWrap: true,
  };

  /**
   *  EVENT HANDLER
   */

  const handleUpdateAttachment = (item, hasAttachment) => async () => {
    const { id, hash } = item;
    const fileHash = hash || id;
    setIsLoadingAttachments(true);

    if (hasAttachment) {
      await deleteOfflineDocument(fileHash).finally(() => {
        setIsLoadingAttachments(false);
      });
    } else {
      await addOfflineDocument(item).finally(() => {
        setIsLoadingAttachments(false);
      });
    }
  };

  const handleClickAction = (item, menuItems) => () => {
    toggleActionDrawer({
      title: item?.displayName,
      items: menuItems,
    });
  };

  const handleClickDelete = (item) => () => {
    showPrompt({
      title: i18n.t('dialog.deleteDocument.title'),
      content: i18n.t('dialog.deleteDocument.content'),
      onOk: handleDelete(item),
    });
  };

  const handleDelete = (item) => async () => {
    const hash = item?.hash;
    const attachment = doc.getAttachment(hash);
    const filteredDocuments = documents
      .filter((filterDoc) => filterDoc?.item?.hash !== hash)
      .map(({ item: mapItem }) => ({
        className: mapItem?.className,
        displayName: mapItem.name,
        properties: { contentType: mapItem?.type, hash: mapItem?.hash, webDavUrl: mapItem?.webDavUrl },
        ...(mapItem?.attributeValues &&
          mapItem?.entityType && { attributeValues: mapItem.attributeValues, entityType: mapItem.entityType }),
      }));

    if (attachment) {
      await attachment.remove();
    }

    onDelete(filteredDocuments);

    enqueueSnackbar(i18n.t('notification.deletedDocument'), {
      key: 'deletedDocument',
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const handleDownload =
    ({ name, hash, type }) =>
    async () => {
      const attachment = await doc.getAttachment(hash);

      if (attachment) {
        const link = window.document.createElement('a');
        const blob = await attachment.getData();
        const file = new File([blob], name, { type });
        const url = window.URL.createObjectURL(file);

        link.href = url;
        link.download = name;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        enqueueSnackbar(i18n.t('notification.couldNotDownload'), {
          key: 'couldNotDownload',
          preventDuplicate: true,
          variant: 'info',
        });
      }
    };

  const handleWebDav = (item) => async () => {
    const document = await fetchDocument(item);
    const { webDavUrl } = getFilePropsFromDocumentEntity(document);
    window.open(webDavUrl);
  };

  const getMenuItems = (item) => {
    const items = [];
    const isRemoteDocument = !!item?.id;
    const { id, hash, webDavUrl } = item;
    const fileHash = hash || id;

    const isAllowedDelete = !isRemoteDocument;
    const hasAttachment = !!getDocument(fileHash);

    if (isOfflineCachePermanent || !!hasAttachment) {
      items.push({
        id: 'open',
        label: i18n.t('label.open'),
        onClick: handleDownload(item),
      });
    }

    if (!isOfflineCachePermanent && !isAllowedDelete) {
      items.push({
        id: 'offline',
        label: hasAttachment ? i18n.t('label.deleteCache') : i18n.t('label.addToCache'),
        onClick: handleUpdateAttachment(item, hasAttachment),
      });
    }

    if (isAllowedDelete && onDelete) {
      items.push({
        color: 'secondary',
        id: 'delete',
        label: i18n.t('label.delete'),
        onClick: handleClickDelete(item),
      });
    }

    if (isOnline && webDavUrl) {
      const webDavAppName = getWebDavAppName(webDavUrl);

      items.push({
        id: 'webDavDownload',
        label: i18n.t('label.editWebApp', { variables: { webDavAppName } }),
        onClick: handleWebDav(item),
      });
    }

    return items;
  };

  if (isLoading) {
    const loadingArray = [...Array(loadingItems).keys()];

    return (
      <List className={classNames(classes.root, className)}>
        {loadingArray.map((key, index) => (
          <ListItem key={key} className={classes.listItem} divider={index < loadingArray.length - 1}>
            <ListItemAvatar>
              <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <List className={classNames(classes.root, className)}>
      {documents.map(({ item, onClick }, index) => {
        const hash = item?.hash;
        const attachment = getDocument(hash);
        const isClickable = hash && attachment;
        const menuItems = getMenuItems(item, attachment);

        return (
          <ListItem
            key={item?.hash}
            className={classes.listItem}
            divider={index < documents.length - 1}
            selected={isClickable && activeId === item.hash}
            sx={{
              ...(!isOfflineCachePermanent && {
                paddingRight: (theme) => theme.spacing(12),
              }),
            }}
            {...(isClickable &&
              onClick && {
                button: true,
                onClick: () => onClick(item),
              })}
          >
            <ListItemAvatar>
              <FileAvatar entity={item} />
            </ListItemAvatar>
            <ListItemText
              primary={item?.name}
              primaryTypographyProps={{ ...listItemTypographyProps, variant: 'body1' }}
              secondaryTypographyProps={listItemTypographyProps}
            />
            {menuItems.length > 0 && (
              <ListItemSecondaryAction>
                {!isOfflineCachePermanent && (
                  <DownloadOfflineButton
                    doc={doc}
                    item={item}
                    isLoadingAttachments={isLoadingAttachments}
                    isOnline={isOnline}
                    setIsLoadingAttachments={setIsLoadingAttachments}
                  />
                )}
                <Trigger
                  {...DEFAULT_TRIGGER_ICON_PROPS}
                  className={classes.trigger}
                  icon={<MoreVertIcon />}
                  onClick={handleClickAction(item, menuItems)}
                  variant="icon"
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

DocumentList.defaultProps = {
  loadingItems: 3,
};

DocumentList.propTypes = {
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  doc: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isOfflineCachePermanent: PropTypes.bool,
  isOnline: PropTypes.bool,
  loadingItems: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
};

export default DocumentList;
