import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { i18n } from '@geomagic/i18n';
import useStickyState from '@geomagic/nam-react-core/utils/useStickyState';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

import OfflineSectionListItem from './OfflineSectionListItem';
import useOfflineBaseTiles from './hooks/useOfflineBaseTiles';
import { ListItemSecondaryAction, Switch } from '@mui/material';
import { BASE_KEY } from './consts';
import getCacheSize from './utils/getCacheSize';
import bytesToSize from '../../../utils/bytesToSize';

const KEY_CHECKED_BASE = 'OfflineMap.checked.base';
const KEY_CACHE_SIZE_BASE = 'OfflineMap.cacheSize.base';

const OfflineMapSections = (props) => {
  const { adminUnits, isOnline, mapProps } = props;
  const [checked, setChecked] = useStickyState(KEY_CHECKED_BASE, false);
  const [cacheSize, setCacheSize] = useStickyState(KEY_CACHE_SIZE_BASE);
  const [isFetching, setIsFetching] = useState(false);
  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const { addBaseTiles, deleteBaseTiles, hasBaseTiles } = useOfflineBaseTiles({
    mapProps,
  });

  const formatCacheString = useCallback(() => (!!cacheSize ? bytesToSize(cacheSize) : ''), [cacheSize]);

  const handleAddBaseTile = async () => {
    await addBaseTiles();
    const size = await getCacheSize([BASE_KEY]);
    setCacheSize(size);
  };

  const handleOnSwitch = async (_event, newChecked) => {
    const execute = newChecked ? handleAddBaseTile : deleteBaseTiles;

    await enqueueLoadingSnackbar({
      loadingText: newChecked
        ? i18n.t('offlineMap.notification.loadingCreate')
        : i18n.t('offlineMap.notification.loadingDelete'),
      finishedText: newChecked ? i18n.t('offlineMap.notification.created') : i18n.t('offlineMap.notification.deleted'),
      func: execute,
      preventDuplicate: true,
      finishedVariant: 'success',
    });

    setChecked(newChecked);
  };

  useEffect(() => {
    const checkCache = async () => {
      const isInCache = await hasBaseTiles();

      if (!isInCache) {
        setCacheSize(null);
        setChecked(isInCache);
      }
    };

    checkCache();
  }, [hasBaseTiles, setCacheSize, setChecked]);

  return (
    <List sx={{ width: '100%' }}>
      <ListItem disableGutters>
        <ListItemText
          primary={i18n.t('label.preference.offlineMap.baseTitle')}
          secondary={checked ? formatCacheString() : ''}
        />
        <ListItemSecondaryAction>
          <Switch
            checked={checked}
            color="primary"
            onChange={handleOnSwitch}
            disabled={isFetching || (!isOnline && !checked)}
          />
        </ListItemSecondaryAction>
      </ListItem>

      <List sx={{ pl: 2, width: '100%' }}>
        {adminUnits?.map((adminUnit) => (
          <OfflineSectionListItem
            adminUnit={adminUnit}
            isDisabled={!checked}
            isFetching={isFetching}
            isOnline={isOnline}
            key={adminUnit.id}
            mapProps={mapProps}
            setIsFetching={setIsFetching}
          />
        ))}
      </List>
    </List>
  );
};

OfflineMapSections.propTypes = {
  adminUnits: PropTypes.array.isRequired,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
};

export default OfflineMapSections;
