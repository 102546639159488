import { getTypedAttributeValue } from '../../../utils';

const getDefaultValuesFromPrefilled = (attributeTypes, prefilledAttributeValues, isIgnoreAttributeTypeDefaultValues) =>
  attributeTypes.reduce((prev, attributeType, index) => {
    const presetDefault = prefilledAttributeValues.find((pAV) => pAV.attributeTypeId === attributeType.id);
    const defaultValue =
      (presetDefault && presetDefault.value) ||
      (isIgnoreAttributeTypeDefaultValues ? undefined : attributeType.defaultValue);
    const typedValue = getTypedAttributeValue(attributeType, defaultValue);

    if (typedValue !== null && typedValue !== undefined) {
      prev[index] = typedValue;
    }

    return prev;
  }, {});

export default getDefaultValuesFromPrefilled;
