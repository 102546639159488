import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DocumentIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import DocumentList from '@components/DocumentList';
import Placeholder from '@components/Placeholder';
import getFilePropsFromDocumentEntity from '@database/getFilePropsFromDocumentEntity';
import getImagesFromAttachments from '@database/getImagesFromAttachments';
import ImageCarousel from '@image/ImageCarousel';

const IMAGE_VIEWER_TABLET_WIDTH = 60;
const IMAGE_VIEWER_MOBILE_HEIGHT = 60;

const DocumentListImageViewer = (props) => {
  const {
    doc: databaseDoc,
    documents: initialDocuments = [],
    imageType = 'image/',
    onDelete,
    isOfflineCachePermanent,
    isOnline,
    placeholderAction,
    PlaceholderIconComponent,
    placeholderSubtitle,
    placeholderTitle,
  } = props;

  const [activeRowId, setActiveRowId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [openImages, setOpenImages] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [images, setImages] = useState([]);

  /**
   *  EVENT HANDLER
   */

  const handleCloseImageViewer = () => {
    setActiveRowId(null);
    setOpenImages(false);
  };

  const handleDelete = (filteredDocuments) => {
    handleCloseImageViewer();
    setDocuments(filteredDocuments);
    onDelete && onDelete(filteredDocuments);
  };

  const handleStepChange = (step) => {
    if (isNumber(step)) {
      setActiveStep(step);
      setOpenImages(true);
    } else {
      handleCloseImageViewer();
    }
  };

  const handleRowClick = (item) => {
    const imageIndex = images.findIndex((image) => image.id === item.hash);
    handleStepChange(imageIndex);
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  useEffect(() => {
    if (openImages) {
      const image = images[activeStep];

      if (image?.id) {
        setActiveRowId(image.id);
      } else {
        setOpenImages(false);
      }
    }
  }, [activeStep, images, openImages]);

  useEffect(() => {
    const getImages = async () => {
      const imageDocuments = documents
        .map((doc) => getFilePropsFromDocumentEntity(doc))
        .filter((doc) => doc?.type?.startsWith(imageType));

      if (imageDocuments.length > 0) {
        const _images = await getImagesFromAttachments(imageDocuments, databaseDoc);

        setImages(_images);
      }
    };

    getImages();
  }, [databaseDoc, documents, imageType]);

  const enhancedDocuments = documents.map((doc) => {
    const enhancedDoc = getFilePropsFromDocumentEntity(doc);
    const isImage = enhancedDoc?.type?.startsWith(imageType);

    return {
      item: enhancedDoc,
      ...(isImage && { button: true, onClick: handleRowClick }),
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: {
          mobile: 'column',
          tablet: 'row',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          ...(openImages && {
            borderBottom: '1px solid',
            borderColor: 'divider',
            height: {
              mobile: `${100 - IMAGE_VIEWER_MOBILE_HEIGHT}%`,
              tablet: '100%',
            },
            width: {
              mobile: '100%',
              tablet: `${100 - IMAGE_VIEWER_TABLET_WIDTH}%`,
            },
          }),
        }}
      >
        {enhancedDocuments.length > 0 ? (
          <ContentRoot scrollable withPadding={false}>
            <DocumentList
              activeId={activeRowId}
              doc={databaseDoc}
              documents={enhancedDocuments}
              isOfflineCachePermanent={isOfflineCachePermanent}
              isOnline={isOnline}
              onDelete={handleDelete}
            />
          </ContentRoot>
        ) : (
          <Placeholder
            action={placeholderAction}
            icon={PlaceholderIconComponent ? <PlaceholderIconComponent /> : <DocumentIcon />}
            subtitle={placeholderSubtitle || i18n.t('placeholder.uploadDocument.subtitle')}
            title={placeholderTitle || i18n.t('placeholder.uploadDocument.title')}
          />
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          ...(openImages && {
            height: {
              mobile: `${IMAGE_VIEWER_MOBILE_HEIGHT}%`,
              tablet: '100%',
            },
            width: {
              mobile: '100%',
              tablet: `${IMAGE_VIEWER_TABLET_WIDTH}%`,
            },
          }),
        }}
      >
        <Trigger
          color="inherit"
          icon={<CloseIcon />}
          onClick={handleCloseImageViewer}
          sx={{
            bgcolor: 'action.active',
            color: (theme) => theme.palette.getContrastText(theme.palette.action.active),
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
            '&:hover': {
              bgcolor: 'action.active',
              color: (theme) => theme.palette.getContrastText(theme.palette.action.active),
            },
          }}
          variant="icon"
        />
        <ImageCarousel activeStep={activeStep} setActiveStep={setActiveStep} images={images} />
      </Box>
    </Box>
  );
};

DocumentListImageViewer.propTypes = {
  doc: PropTypes.object.isRequired,
  documents: PropTypes.array,
  imageType: PropTypes.string,
  isOfflineCachePermanent: PropTypes.bool,
  isOnline: PropTypes.bool,
  onDelete: PropTypes.func,
  placeholderAction: PropTypes.node,
  PlaceholderIconComponent: PropTypes.object,
  placeholderSubtitle: PropTypes.string,
  placeholderTitle: PropTypes.string,
};

export default DocumentListImageViewer;
